:root {
  --ML__static-fonts: true;
}

$color_1: transparent;
$color_2: #bc2612;
$color_3: #0c7f99;
$color_4: #007cb2;
$color_5: var(--_placeholder-color);
$color_6: var(--tooltip-color);
$font-family_1: inherit;
$font-family_2: KaTeX_Main;
$font-family_3: var(--_text-font-family);
$font-family_4: KaTeX_Math;
$font-family_5: KaTeX_AMS;
$font-family_6: KaTeX_Caligraphic;
$font-family_7: KaTeX_Fraktur;
$font-family_8: KaTeX_Typewriter;
$font-family_9: KaTeX_Script;
$font-family_10: KaTeX_SansSerif;
$font-family_11: KaTeX_Size1;
$font-family_12: KaTeX_Size2;
$font-family_13: KaTeX_Size3;
$font-family_14: KaTeX_Size4;
$font-family_15: KaTeX_Main, Times New Roman, serif;
$font-family_16: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
  Droid Sans, Helvetica Neue, sans-serif;
$background-color_1: rgba(204, 0, 65, 0.1);

.ML__container {
  min-height: auto !important;
  --_hue: var(--hue, 212);
  --_placeholder-color: var(--placeholder-color, hsl(var(--_hue), 40%, 49%));
  --_placeholder-opacity: var(--placeholder-opacity, 0.4);
  --_text-font-family: var(
    --text-font-family,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif
  );
}
.ML__sr-only {
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
  clip-path: inset(50%);
  white-space: nowrap;
}
.ML__base {
  display: inline-block;
  border: 0;
  box-sizing: content-box;
  cursor: text;
  font-family: $font-family_1;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
  vertical-align: baseline;
  visibility: inherit;
  width: min-content;
}
.ML__is-inline {
  display: inline-block;
}
.ML__strut {
  display: inline-block;
  min-height: 0.5em;
}
.ML__strut--bottom {
  display: inline-block;
  min-height: 0.5em;
}
.ML__small-delim {
  font-family: $font-family_2;
}
.ML__text {
  font-family: $font-family_3;
  white-space: pre;
}
.ML__cmr {
  font-family: $font-family_2;
  font-style: normal;
}
.ML__mathit {
  font-family: $font-family_4;
  font-style: italic;
}
.ML__mathbf {
  font-family: $font-family_2;
  font-weight: 700;
}
.ML__mathbfit {
  font-family: $font-family_4;
  font-style: italic;
  font-weight: 700;
}
.lcGreek.ML__mathbf {
  font-family: $font-family_4;
}
.ML__ams {
  font-family: $font-family_5;
}
.ML__bb {
  font-family: $font-family_5;
}
.ML__cal {
  font-family: $font-family_6;
}
.ML__frak {
  font-family: $font-family_7;
}
.ML__tt {
  font-family: $font-family_8;
}
.ML__script {
  font-family: $font-family_9;
}
.ML__sans {
  font-family: $font-family_10;
}
.ML__series_el {
  font-weight: 100;
}
.ML__series_ul {
  font-weight: 100;
}
.ML__series_l {
  font-weight: 200;
}
.ML__series_sl {
  font-weight: 300;
}
.ML__series_sb {
  font-weight: 500;
}
.ML__bold {
  font-weight: 700;
}
.ML__series_eb {
  font-weight: 800;
}
.ML__series_ub {
  font-weight: 900;
}
.ML__series_uc {
  font-stretch: ultra-condensed;
}
.ML__series_ec {
  font-stretch: extra-condensed;
}
.ML__series_c {
  font-stretch: condensed;
}
.ML__series_sc {
  font-stretch: semi-condensed;
}
.ML__series_sx {
  font-stretch: semi-expanded;
}
.ML__series_x {
  font-stretch: expanded;
}
.ML__series_ex {
  font-stretch: extra-expanded;
}
.ML__series_ux {
  font-stretch: ultra-expanded;
}
.ML__it {
  font-style: italic;
}
.ML__shape_ol {
  -webkit-text-stroke: 1px #000;
  text-stroke: 1px #000;
  color: $color_1;
}
.ML__shape_sc {
  font-variant: small-caps;
}
.ML__shape_sl {
  font-style: oblique;
}
.ML__emph {
  color: $color_2;
  .ML__emph {
    color: $color_3;
  }
}
.ML__highlight {
  background: #edd1b0;
  color: $color_4;
}
.ML__center {
  text-align: center;
}
.ML__left {
  text-align: left;
}
.ML__right {
  text-align: right;
}
.ML__label_padding {
  padding: 0 0.5em;
}
.ML__frac-line {
  min-height: 1px;
  width: 100%;
  &:after {
    background: currentColor;
    box-sizing: content-box;
    content: '';
    display: block;
    margin-top: max(-1px, -0.04em);
    min-height: max(1px, 0.04em);
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    transform: translate(0);
  }
}
.ML__sqrt {
  display: inline-block;
}
.ML__sqrt-sign {
  display: inline-block;
  position: relative;
}
.ML__sqrt-line {
  display: inline-block;
  height: max(1px, 0.04em);
  width: 100%;
  &:before {
    background: currentColor;
    content: '';
    display: block;
    margin-top: min(-1px, -0.04em);
    min-height: max(1px, 0.04em);
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    transform: translate(0);
  }
  &:after {
    border-bottom-width: 1px;
    content: ' ';
    display: block;
    margin-top: -0.1em;
  }
}
.ML__sqrt-index {
  margin-left: 0.27777778em;
  margin-right: -0.55555556em;
}
.ML__delim-size1 {
  font-family: $font-family_11;
}
.ML__delim-size2 {
  font-family: $font-family_12;
}
.ML__delim-size3 {
  font-family: $font-family_13;
}
.ML__delim-size4 {
  font-family: $font-family_14;
}
.ML__delim-mult {
  .delim-size1 {
    > span {
      font-family: $font-family_11;
    }
  }
  .delim-size4 {
    > span {
      font-family: $font-family_14;
    }
  }
}
.ML__accent-body {
  > span {
    font-family: $font-family_2;
    width: 0;
  }
}
.ML__accent-vec {
  left: 0.24em;
  position: relative;
}
.ML__latex {
  direction: ltr;
  display: inline-block;
  font-family: $font-family_15;
  font-size-adjust: none;
  font-stretch: normal;
  font-style: normal;
  font-variant-caps: normal;
  letter-spacing: normal;
  line-height: 1.2;
  text-align: left;
  text-indent: 0;
  text-rendering: auto;
  word-wrap: normal;
  text-shadow: none;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: min-content;
  word-spacing: normal;
  .style-wrap {
    position: relative;
  }
  .ML__left-right {
    display: inline-block;
  }
  .ML__mfrac {
    display: inline-block;
  }
  .ML__vlist-t {
    border-collapse: collapse;
    display: inline-table;
    table-layout: fixed;
  }
  .ML__vlist-r {
    display: table-row;
  }
  .ML__vlist {
    display: table-cell;
    position: relative;
    vertical-align: bottom;
    > span {
      display: block;
      height: 0;
      position: relative;
      > span {
        display: inline-block;
      }
      > .ML__pstrut {
        overflow: hidden;
        width: 0;
      }
    }
  }
  .ML__vlist-t2 {
    margin-right: -2px;
  }
  .ML__vlist-s {
    display: table-cell;
    font-size: 1px;
    min-width: 2px;
    vertical-align: bottom;
    width: 2px;
  }
  .ML__msubsup {
    text-align: left;
  }
  .ML__negativethinspace {
    display: inline-block;
    height: 0.71em;
    margin-left: -0.16667em;
  }
  .ML__thinspace {
    display: inline-block;
    height: 0.71em;
    width: 0.16667em;
  }
  .ML__mediumspace {
    display: inline-block;
    height: 0.71em;
    width: 0.22222em;
  }
  .ML__thickspace {
    display: inline-block;
    height: 0.71em;
    width: 0.27778em;
  }
  .ML__enspace {
    display: inline-block;
    height: 0.71em;
    width: 0.5em;
  }
  .ML__quad {
    display: inline-block;
    height: 0.71em;
    width: 1em;
  }
  .ML__qquad {
    display: inline-block;
    height: 0.71em;
    width: 2em;
  }
  .ML__llap {
    display: inline-block;
    position: relative;
    width: 0;
    > .ML__inner {
      position: absolute;
      right: 0;
    }
    > .ML__fix {
      display: inline-block;
    }
  }
  .ML__rlap {
    display: inline-block;
    position: relative;
    width: 0;
    > .ML__inner {
      position: absolute;
      left: 0;
    }
    > .ML__fix {
      display: inline-block;
    }
  }
  .ML__rule {
    border: 0 solid;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
  }
  .overline {
    .overline-line {
      width: 100%;
      &:before {
        border-bottom-style: solid;
        border-bottom-width: max(1px, 0.04em);
        content: '';
        display: block;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
      &:after {
        border-bottom-style: solid;
        border-bottom-width: max(1px, 0.04em);
        content: '';
        display: block;
        margin-top: -1px;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
    }
  }
  .underline {
    .underline-line {
      width: 100%;
      &:before {
        border-bottom-style: solid;
        border-bottom-width: max(1px, 0.04em);
        content: '';
        display: block;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
      &:after {
        border-bottom-style: solid;
        border-bottom-width: max(1px, 0.04em);
        content: '';
        display: block;
        margin-top: -1px;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
    }
  }
  .ML__stretchy {
    display: block;
    left: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;
    &:after {
      content: '';
    }
    &:before {
      content: '';
    }
    svg {
      display: block;
      height: inherit;
      position: absolute;
      width: 100%;
      fill: currentColor;
      stroke: currentColor;
      fill-rule: nonzero;
      fill-opacity: 1;
      stroke-width: 1;
      stroke-linecap: butt;
      stroke-linejoin: miter;
      stroke-miterlimit: 4;
      stroke-dasharray: none;
      stroke-dashoffset: 0;
      stroke-opacity: 1;
    }
  }
  .slice-1-of-2 {
    left: 0;
    display: inline-flex;
    overflow: hidden;
    position: absolute;
    width: 50.2%;
  }
  .slice-2-of-2 {
    display: inline-flex;
    overflow: hidden;
    position: absolute;
    width: 50.2%;
    right: 0;
  }
  .slice-1-of-3 {
    left: 0;
    width: 25.1%;
    display: inline-flex;
    overflow: hidden;
    position: absolute;
  }
  .slice-2-of-3 {
    display: inline-flex;
    overflow: hidden;
    position: absolute;
    left: 25%;
    width: 50%;
  }
  .slice-3-of-3 {
    right: 0;
    width: 25.1%;
    display: inline-flex;
    overflow: hidden;
    position: absolute;
  }
  .slice-1-of-1 {
    display: inline-flex;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
  }
  .ML__nulldelimiter {
    display: inline-block;
  }
  .ML__op-group {
    display: inline-block;
  }
  .ML__op-symbol {
    position: relative;
  }
  .ML__op-symbol.ML__small-op {
    font-family: $font-family_11;
  }
  .ML__op-symbol.ML__large-op {
    font-family: $font-family_12;
  }
  .ML__mtable {
    .ML__vertical-separator {
      box-sizing: border-box;
      display: inline-block;
      min-width: 1px;
    }
    .ML__arraycolsep {
      display: inline-block;
    }
    .col-align-m {
      > .ML__vlist-t {
        text-align: center;
      }
    }
    .col-align-c {
      > .ML__vlist-t {
        text-align: center;
      }
    }
    .col-align-l {
      > .ML__vlist-t {
        text-align: left;
      }
    }
    .col-align-r {
      > .ML__vlist-t {
        text-align: right;
      }
    }
  }
}
[data-href] {
  cursor: pointer;
}
.ML__error {
  background-color: $background-color_1;
  background-image: radial-gradient(ellipse at center, #cc0041, transparent 70%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 3px 3px;
  display: inline-block;
  padding-bottom: 3px;
  > .ML__error {
    background: transparent;
    padding: 0;
  }
}
.ML__placeholder {
  color: $color_5;
  font-family: $font-family_16;
  opacity: var(--_placeholder-opacity);
  padding-left: 0.4ex;
  padding-right: 0.4ex;
}
.ML__notation {
  box-sizing: border-box;
  line-height: 0;
  position: absolute;
}
.ML__tooltip-container {
  position: relative;
  transform: scale(0);
  .ML__tooltip-content {
    background: var(--tooltip-background-color);
    border: var(--tooltip-border);
    border-radius: var(--tooltip-border-radius);
    display: inline-table;
    max-width: 400px;
    padding: 12px;
    position: fixed;
    visibility: hidden;
    width: max-content;
    z-index: 2;
    --_selection-color: var(--tooltip-color);
    box-shadow: var(--tooltip-box-shadow);
    color: $color_6;
    opacity: 0;
    transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
    .ML__text {
      white-space: normal;
    }
    .ML__base {
      display: contents;
    }
  }
  &:hover {
    .ML__tooltip-content {
      font-size: 0.75em;
      opacity: 1;
      transform: scale(1) translateY(3em);
      visibility: visible;
    }
  }
}

.ML {
  &__keyboard {
    --keyboard-zindex: 1500;
  }

  &__preview * {
    font-size: 1.8rem;
  }
}

math-field {
  font-size: 2.5rem;
  outline: none;
  width: 100%;
  min-height: 5rem;
}

#mathlive-suggestion-popover {
  z-index: 1500 !important;
}
