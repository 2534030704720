@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'abstracts/breakpoints';

@import 'fonts/calibri';
@import 'fonts/goldplay';
@import 'fonts/katex';
@import 'fonts/opendyslexic';

@import 'base/base';
@import 'base/variables';

@import 'components/accordion';
@import 'components/dialog';
@import 'components/math-live';
@import 'components/ng-select';
@import 'components/popover';
@import 'components/text-editor';
@import 'components/tab';

@import 'theme/colors';

@import 'ngx-toastr/toastr';

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #cdcfd1 transparent;
}

*::-webkit-scrollbar {
  width: 3px !important;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #cdcfd1;
  border-radius: 10px;
  border: 3px solid transparent;
}
