
@font-face {
  font-family: 'OpenDyslexic';
  src: url(https://s.educacaoadventista.org.br/fontes/opendyslexic/opendyslexic-regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OpenDyslexic';
  src: url(https://s.educacaoadventista.org.br/fontes/opendyslexic/opendyslexic-bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
