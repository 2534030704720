:root {
  --color-theme: var(--color-theme-500);
  --color-theme-50: theme('colors.modules.eclass.50');
  --color-theme-100: theme('colors.modules.eclass.100');
  --color-theme-200: theme('colors.modules.eclass.200');
  --color-theme-300: theme('colors.modules.eclass.300');
  --color-theme-400: theme('colors.modules.eclass.400');
  --color-theme-500: theme('colors.modules.eclass.500');
  --color-theme-600: theme('colors.modules.eclass.600');
  --color-theme-700: theme('colors.modules.eclass.700');
  --color-theme-800: theme('colors.modules.eclass.800');
  --color-theme-900: theme('colors.modules.eclass.900');
  --color-theme-950: theme('colors.modules.eclass.950');
  --color-theme-primary: var(--color-theme-500);
  --color-theme-secondary: var(--color-theme-50);
  --color-theme-light: theme('colors.modules.eclass.light');
  --color-theme-lighter: theme('colors.modules.eclass.lighter');
  --color-theme-dark: theme('colors.modules.eclass.dark');
  --color-theme-darker: theme('colors.modules.eclass.darker');
  --color-theme-contrast: theme('colors.modules.eclass.contrast');
}
