*,
html,
body {
  @apply font-goldplay text-base font-medium;
}

@mixin columns($n, $g) {
  width: calc((100% / $n) - ((($n - 1) / $n) * $g));
}

@layer components {
  /* stylelint-disable */
  $cols: 2, 3, 4, 5, 6;
  $gaps: (
    '4': 1rem,
    '8': 2rem,
  );
  /* stylelint-enable */

  @each $col in $cols {
    .col#{$col} {
      @each $gap, $value in $gaps {
        &-gap#{$gap} {
          @include columns($col, $value);
        }
      }
    }
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  /* Firefox */
  * {
    scrollbar-color: #d0d0d0 #fff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;
    border-radius: 10px;
    border: 3px none transparent;
  }
}
