
@font-face {
  font-family: 'Calibri';
  src: url(https://s.educacaoadventista.org.br/fontes/calibri/calibri-regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url(https://s.educacaoadventista.org.br/fontes/calibri/calibri-italic.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url(https://s.educacaoadventista.org.br/fontes/calibri/calibri-bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url(https://s.educacaoadventista.org.br/fontes/calibri/calibri-bold-italic.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
