.ck-powered-by {
  display: none !important;
}

.readonly {
  .ck-editor {
    .ck-content,
    .ck-editor__main {
      min-height: 0;
    }
  }
}

.ck-editor {
  display: block;

  &__main,
  &__editable {
    display: block;
    min-height: 12rem;
    max-height: fit-content;

    ul li,
    ol li {
      margin-left: 2rem;
    }

    td {
      display: table-cell;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    .ck-read-only {
      border: none !important;
    }
  }

  .ck-toolbar__items {
    flex-wrap: wrap !important;
  }

  .ck-editor__editable {
    font-size: 16px;
    font-family: sans-serif;

    strong,
    b {
      font-family: inherit;
      font-size: inherit;
      font-weight: bold;
    }

    em,
    i {
      font-family: inherit;
      font-size: inherit;
      font-style: italic;
    }

    u {
      font-family: inherit;
      font-size: inherit;
      text-decoration: underline;
    }

    s,
    del {
      font-family: inherit;
      font-size: inherit;
      text-decoration: line-through;
    }

    mark {
      font-size: inherit;
    }

    strong u,
    u strong {
      font-weight: bold;
      text-decoration: underline;
    }

    h1,
    h2,
    h3 {
      font-size: 24px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      line-height: 1.2;
    }

    h2 {
      font-size: 22px;
    }

    h3 {
      font-size: 20px;
    }
  }
}
