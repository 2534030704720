@font-face {
  font-display: 'swap';
  font-family: KaTeX_AMS;
  font-style: normal;
  font-weight: 400;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_AMS-Regular.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Caligraphic;
  font-style: normal;
  font-weight: 700;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Caligraphic-Bold.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Caligraphic;
  font-style: normal;
  font-weight: 400;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Caligraphic-Regular.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Fraktur;
  font-style: normal;
  font-weight: 700;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Fraktur-Bold.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Fraktur;
  font-style: normal;
  font-weight: 400;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Fraktur-Regular.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Main;
  font-style: italic;
  font-weight: 700;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Main-BoldItalic.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Main;
  font-style: normal;
  font-weight: 700;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Main-Bold.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Main;
  font-style: italic;
  font-weight: 400;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Main-Italic.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Main;
  font-style: normal;
  font-weight: 400;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Main-Regular.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Math;
  font-style: italic;
  font-weight: 700;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Math-BoldItalic.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Math;
  font-style: italic;
  font-weight: 400;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Math-Italic.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: 'KaTeX_SansSerif';
  font-style: normal;
  font-weight: 700;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_SansSerif-Bold.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: 'KaTeX_SansSerif';
  font-style: italic;
  font-weight: 400;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_SansSerif-Italic.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: 'KaTeX_SansSerif';
  font-style: normal;
  font-weight: 400;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_SansSerif-Regular.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Script;
  font-style: normal;
  font-weight: 400;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Script-Regular.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Size1;
  font-style: normal;
  font-weight: 400;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Size1-Regular.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Size2;
  font-style: normal;
  font-weight: 400;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Size2-Regular.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Size3;
  font-style: normal;
  font-weight: 400;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Size3-Regular.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Size4;
  font-style: normal;
  font-weight: 400;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Size4-Regular.woff2) format('woff2');
}

@font-face {
  font-display: 'swap';
  font-family: KaTeX_Typewriter;
  font-style: normal;
  font-weight: 400;
  src: url(https://s.educacaoadventista.org.br/fontes/katex/KaTeX_Typewriter-Regular.woff2) format('woff2');
}
