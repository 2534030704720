.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  cdk-dialog-container {
    @apply relative rounded-3xl bg-white;
  }

  .no-padding cdk-dialog-container {
    padding: 0 !important;
  }

  .cdk-overlay-backdrop {
    position: absolute;
    inset: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0;

    &.cdk-overlay-backdrop-showing {
      opacity: 1;
    }

    &.cdk-overlay-dark-backdrop {
      background: rgb(0 0 0 / 50%);
    }
  }

  .cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;

    .cdk-overlay-pane {
      pointer-events: auto;
      box-sizing: border-box;
      z-index: 1000;
      display: flex;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
