.ck-powered-by {
  display: none !important;
}

.ck-editor--readonly {
  .ck-content,
  .ck-editor__main {
    min-height: 0;
  }
}

.preview-content img,
.preview-content .math-tex {
  vertical-align: middle;
  display: inline-block;
  height: 1.2em !important;
}

.ck-content .math-tex,
.ck-content .math-tex img {
  display: inline-block !important;
  vertical-align: middle !important;
  height: 1em !important;
  width: auto !important;
  max-height: none !important;
}

.ck-content img.math-tex {
  min-width: 2em !important;
  min-height: 1.5em !important;
  width: auto !important;
  height: auto !important;
}

/* stylelint-disable-next-line selector-class-pattern */
.ck-content img.image_resized {
  min-width: 2em !important;
  min-height: 1.5em !important;
  width: auto !important;
  height: auto !important;
  max-width: 100% !important;
}

.ck-content .fraction {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em !important;
  line-height: 1.2 !important;
  text-align: center;
}

.ck-content .fraction sup,
.ck-content .fraction sub {
  font-size: 0.9em !important;
  line-height: 1 !important;
  vertical-align: baseline !important;
  position: relative !important;
}

.ck-content .fraction img {
  height: 1em !important;
  width: auto !important;
  max-width: none !important;
}

.ck-content img {
  max-width: 100% !important;
  height: auto !important;
  vertical-align: middle !important;
}

.ck-content sup,
.ck-content sub {
  font-size: 0.9em !important;
  vertical-align: baseline !important;
  display: inline-block;
}

.ck-content img,
.ck-content .math-tex {
  display: inline-block !important;
  vertical-align: middle !important;
}

.ck-editor {
  display: block;

  &__main,
  &__editable {
    display: block;
    min-height: 12rem;
    max-height: fit-content;

    ul li,
    ol li {
      margin-left: 2rem;
    }

    td {
      display: table-cell;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    .ck-read-only {
      border: none !important;
    }
  }

  .ck-toolbar__items {
    flex-wrap: wrap !important;
  }

  .ck-editor__editable {
    font-size: 16px;
    font-family: sans-serif;

    strong,
    b {
      font-family: inherit;
      font-size: inherit;
      font-weight: bold;
    }

    em,
    i {
      font-family: inherit;
      font-size: inherit;
      font-style: italic;
    }

    u {
      font-family: inherit;
      font-size: inherit;
      text-decoration: underline;
    }

    s,
    del {
      font-family: inherit;
      font-size: inherit;
      text-decoration: line-through;
    }

    mark {
      font-size: inherit;
    }

    strong u,
    u strong {
      font-weight: bold;
      text-decoration: underline;
    }

    h1,
    h2,
    h3 {
      font-size: 24px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      line-height: 1.2;
    }

    h2 {
      font-size: 22px;
    }

    h3 {
      font-size: 20px;
    }
  }
}
